<template>
  <div class="hold-transition">
    <!-- <loading v-if="cargando" /> -->
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Sitios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">TIF</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Basico</li>
                  <li class="breadcrumb-item active">Sitios</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-10"></div>
                  <div class="col-md-2">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_tifsitio_export"
                        v-if="$store.getters.can('tif.sitios.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        v-if="$store.getters.can('tif.sitios.create')"
                        @click="save()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table
                  id="ofertas"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th class="text-center">
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Direccion
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.direccion"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Telefono
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.telefono"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Celular
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.celular"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Contacto
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre_contacto"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Tipo de sitio
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_sitio"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipoSitio in listasForms.tipoSitio"
                            :key="tipoSitio.numeracion"
                            :value="tipoSitio.numeracion"
                          >
                            {{ tipoSitio.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">Geocerca</th>
                      <th class="text-center">
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="sitio in sitios.data" :key="sitio.id">
                      <td>{{ sitio.id }}</td>
                      <td class="text-nowrap">{{ sitio.nombre }}</td>
                      <td>{{ sitio.direccion }}</td>
                      <td class="text-center">
                        {{ sitio.telefono }}
                      </td>
                      <td class="text-center">
                        {{ sitio.celular }}
                      </td>
                      <td class="text-wrap text-xs">
                        {{ sitio.nombre_contacto }}
                      </td>
                      <td class="text-wrap text-xs">
                        {{ sitio.nTipoSitio }}
                      </td>
                      <td class="text-wrap text-xs">
                        {{ sitio.geocercas_zona.nombre }}
                      </td>
                      <td class="text-center">
                        <span
                          v-if="$store.getters.can('tif.sitios.show')"
                          class="badge"
                          data-toggle="modal"
                          data-target="#modal-form-estado"
                          style="cursor: pointer"
                          v-bind:class="[
                            sitio.estado == 1
                              ? 'badge-success'
                              : 'badge-danger',
                          ]"
                          @click="llenarModal(sitio.id, sitio.estado)"
                        >
                          {{ sitio.nEstado }}
                        </span>
                        <span
                          v-else
                          class="badge"
                          v-bind:class="[
                            sitio.estado == 1
                              ? 'badge-success'
                              : 'badge-danger',
                          ]"
                        >
                          {{ sitio.nEstado }}
                        </span>
                      </td>
                      <td style="width: 50px" class="text-left">
                        <div class="btn-group float-left">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="$store.getters.can('tif.sitios.edit')"
                            @click="save(sitio)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="$store.getters.can('tif.sitios.delete')"
                            @click="destroy(sitio)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="sitios.total">
                  <p>
                    Mostrando del <b>{{ sitios.from }}</b> al
                    <b>{{ sitios.to }}</b> de un total:
                    <b>{{ sitios.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="sitios"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <TifSitiosExport ref="TifSitiosExport" />
        <TifSitiosEstado ref="TifSitiosEstado" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import TifSitiosExport from "./TifSitiosExport";
import TifSitiosEstado from "./TifSitiosEstado";
// import Loading from "../../../../components/Loading";

export default {
  name: "TifSitiosIndex",
  components: {
    pagination,
    TifSitiosExport,
    TifSitiosEstado,
    // Loading,
  },
  data() {
    return {
      sitios: {},
      filtros: {
        nombre: null,
        sitio: null,
        direccion: null,
        telefono: null,
        celular: null,
        nombre_contacto: null,
        tipo_sitio: null,
        estado: null,
      },
      listasForms: {
        estados: {},
        tipoSitio: {},
      },
      cargando: false,
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/tif/sitios?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.sitios = response.data;
          this.cargando = false;
        });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    getTipoSitio() {
      axios.get("/api/lista/95").then((response) => {
        this.listasForms.tipoSitio = response.data;
      });
    },

    save(sitio = null) {
      let accion = "Crear";
      if (sitio) {
        accion = "Editar";
      }
      return this.$router.push({
        name: "/Tif/SitiosForm",
        params: {
          accion,
          sitio,
        },
      });
    },

    destroy(sitio) {
      this.$swal({
        title: "Esta seguro de eliminar el sitio: " + sitio.nombre + "?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/tif/sitios/destroy/" + sitio.id).then(() => {
            this.cargando = false;
            this.$swal({
              icon: "success",
              title: "Se eliminó el sitio exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getIndex();
          });
        }
      });
    },

    llenarModal(id, estado) {
      if (this.$store.getters.can("tif.sitios.show")) {
        this.$refs.TifSitiosEstado.llenar_modal(id, estado);
      }
    },
  },
  mounted() {
    this.getIndex();
    this.getEstados();
    this.getTipoSitio();
  },
};
</script>
